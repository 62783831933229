document.addEventListener('DOMContentLoaded', function () {
  const logos = document.querySelectorAll('#logosNav a');
  const messagePrincipal = document.getElementById('messagePrincipal');
  const messages = document.querySelectorAll('.e-message-home');

  function updateMessagePrincipalVisibility() {
        // Comprueba si hay algún e-message activo
        const isActiveMessageVisible = Array.from(messages).some(article => !article.classList.contains('dn'));

        // Si no hay mensajes activos, muestra el mensaje principal, de lo contrario, ocúltalo
        if (!isActiveMessageVisible) {
          messagePrincipal.classList.remove('dn');
          document.getElementById('html').classList.remove('is-active-message');
        } else {
          messagePrincipal.classList.add('dn');
          document.getElementById('html').classList.add('is-active-message');
        }
      }

      function toggleActiveClass(logo) {
        // Remueve la clase 'is-active' de todos los logos
        logos.forEach(function(otherLogo) {
          otherLogo.classList.remove('is-active');
        });

        // Añade la clase 'is-active' al logo actual
        logo.classList.add('is-active');
      }

    // Asegura que solo un e-message esté activado al mismo tiempo
    function activateMessage(key, logo) {
        // Oculta todos los mensajes
        messages.forEach(function(message) {
          message.classList.add('dn');
          message.classList.remove('is-active');
        });

        // Muestra solo el mensaje correspondiente
        const targetArticle = document.querySelector('.e-message-home[data-key="' + key + '"]');
        if (targetArticle) {
          targetArticle.classList.remove('dn');
          targetArticle.classList.add('is-active');
            toggleActiveClass(logo); // Añadir clase is-active al logo
            updateMessagePrincipalVisibility();
          }
        }

      // Añade la funcionalidad de cierre para cada mensaje
      function addCloseFunctionality() {
      const closeButtons = document.querySelectorAll('.item-clossed');

      closeButtons.forEach(function(button) {
        button.addEventListener('click', function() {
          // Encuentra el elemento padre más cercano que sea un 'e-message-home'
          const message = this.closest('.e-message-home');
          if (message) {
            message.classList.add('dn'); // Oculta el mensaje
            message.classList.remove('is-active'); // Opcional, si usas esta clase para algo más

            // Encuentra el logo asociado usando el data-key del mensaje
            const key = message.getAttribute('data-key');
            const associatedLogo = document.querySelector(`#logosNav a[data-click-key="${key}"]`);
            if (associatedLogo) {
              associatedLogo.classList.remove('is-active'); // Remueve la clase 'is-active' del logo asociado
            }

            // Actualiza la visibilidad del mensaje principal después de cerrar un e-message
            updateMessagePrincipalVisibility();
          }
        });
      });
    }

    // Añade una función para manejar la activación de mensajes basada en el ancho del viewport
    function handleActivation(event) {
      const minWidth = 1140;
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        // Obtén la clave del logo
        const key = this.getAttribute('data-click-key');

        // Si el ancho del viewport es mayor a 1140px, activa el mensaje en mouseenter.
        // Si no, activa el mensaje en click.
        if ((event.type === 'mouseenter' && viewportWidth > minWidth) || (event.type === 'click' && viewportWidth <= minWidth)) {
            event.preventDefault(); // Prevenir la acción por defecto para click
            activateMessage(key, this);
          }
        }

        logos.forEach(function(logo) {
        // Usar la misma función para manejar ambos eventos
        logo.addEventListener('mouseenter', handleActivation);
        logo.addEventListener('click', handleActivation);
      });


    // Añade un listener para el evento keydown para todo el documento
    document.addEventListener('keydown', function(event) {
      // Comprueba si la tecla presionada es 'Esc'
      if (event.key === "Escape" || event.keyCode === 27) {
        // Encuentra todos los mensajes que están actualmente visibles
        const openMessages = document.querySelectorAll('.e-message-home:not(.dn)');

        openMessages.forEach(function(message) {
          // Cierra los mensajes abiertos
          message.classList.add('dn');
          message.classList.remove('is-active');
          
          // También debes asegurarte de remover la clase 'is-active' de los logos asociados
          const key = message.getAttribute('data-key');
          const associatedLogo = document.querySelector(`#logosNav a[data-click-key="${key}"]`);
          if (associatedLogo) {
            associatedLogo.classList.remove('is-active');
          }
        });

        // Luego de cerrar los mensajes, actualiza la visibilidad del mensaje principal
        updateMessagePrincipalVisibility();
      }
    }); 


    // Llama a la función después de definir tus manejadores de eventos para logos
    addCloseFunctionality();
  });




