// Vendor  node_modules
// jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Fancybox
require('@fancyapps/fancybox');

// Scripts
require('./modules/scripts');



	









